import React from 'react';

import FieldList_TableGroup from '../../../components/plain/FieldList_TableGroup';

import { BOOK_PATH } from '../../../util/routes';
import { FORM_BOOK_TABLE } from '../../../util/consts/forms';

class TabBrDocLinks extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		let plain = this.props.plain;
		let readOnly = this.props.mode == 'special';
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return (
			<div style={{padding: '20px 20px 20px 20px' }}>
				<FieldList_TableGroup instance={instance} path={'docLink'} parentField={parentField}
					plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter} ignoreTitle={true} 
					readOnly={readOnly}
					table={{
						columns: [
							{ columnId: 'docLinkType', styles: {tdHeader: {width: '20%'}} },
							{ columnId: 'docLinkValue', styles: {tdHeader: {width: '60%'}},
								refNodes: [
									{
										classId: 'Book', plainFormPath: BOOK_PATH, 
										selectInstanceFormId: FORM_BOOK_TABLE,
										selectInstanceFormTitle: 'Книги'
									}
								]
							},
							{ columnId: 'docLinkIssue', styles: {tdHeader: {width: '20%'}} },
						]
					}}
					styles={{
						table: {width: '100%'},
						trHeader: {height: '33px'}
					}}
				/>
			</div>
		);
	}
}

export default TabBrDocLinks;