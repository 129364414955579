import React, { createRef } from 'react';
import SelectionList from '../../selectionList/SelectionList';
import FilterValueObjectList from './FilterValueObjectList';
import {findConditionIndex} from './util';
import { getCurrentResource } from '../../../util/tools';

import {
	CONDITION_NONE,
	CONDITION_IS_NOT_NULL,
	CONDITION_IS_NULL,
	CONDITION_INCLUDE,
	CONDITION_NOT_INCLUDE,
} from './util';

class FilterLinked extends React.Component {
	
	/*
		props:
			filter
			filterIndex
			holder
			rateView
			handleFilterChanged
	*/
	constructor(props) {
		super(props);
		props.holder.registerFilterComponent(this, props.filterIndex); 
		this.refConditionList = React.createRef();
		this.resCondition = getCurrentResource().components.rateview.conditions.conditions;
		this.conditions = [
			{id: CONDITION_NONE, title: this.resCondition.none.title},
		];
		let filter = props.filter;
		for(let conditionId of filter.filterModel.listConditions) {
			this.conditions.push(
				{id: conditionId, title: this.resCondition[conditionId].title}
			);
		}
		let conditionIndex = findConditionIndex(this.conditions, filter.conditionId, 0);
		
		if( filter.listObjectRef == undefined || filter.listObjectRef == null )
			filter.listObjectRef = [];		
		
		this.state = {
			filter: props.filter,
			conditionIndex: conditionIndex
		}
	}
	updateFilter = (filter) => {
		let conditionIndex = findConditionIndex(this.conditions, filter.conditionId, 0);
		this.refConditionList.current.setSelectedIndex( conditionIndex );
		this.setState({filter: filter, conditionIndex: conditionIndex});
	}
	
	handleConditionSelection = (index) => {
		if( index == -1 )
			index = 0;
		let cond = this.conditions[index];
		this.props.filter.conditionId = cond.id;
		this.setState({conditionIndex: index}, this.handleFilterChanged);
	}	
	handleFilterChanged = (filter) => {
		if( ! filter )
			filter = this.props.filter;

		if( filter.conditionId == CONDITION_NONE ) {
			filter.active = false;
			filter.bodyText = '';
		} else if( filter.conditionId == CONDITION_IS_NOT_NULL || filter.conditionId == CONDITION_IS_NULL ) {
			filter.active = true;
			filter.bodyText = filter.title + ' ' + this.resCondition[filter.conditionId].title;
		} else if( filter.conditionId == CONDITION_INCLUDE || filter.conditionId == CONDITION_NOT_INCLUDE ) {
			if( filter.listValue.length == 0 ) {
				filter.active = false;
				filter.bodyText = '';
			} else {
				filter.active = true;
				filter.bodyText = filter.title + ' ' + this.resCondition[filter.conditionId].title + ' ( ';
				let first = true;
				for(let objectMaster of filter.listValueObject) {
					if( first )
						first = false;
					else
						filter.bodyText += ', ';
					filter.bodyText += "'" + objectMaster.card + "'";
				}
				filter.bodyText += ' )';
			}
		}
		
		this.props.handleFilterChanged( filter );
	}
	
	
	render() {
		return (
			<div style={{display: 'flex'}}>
				<div style={{width: '250px', border: '1px solid black'}}>
					<SelectionList 
						ref={this.refConditionList}
						items={this.conditions} 
						handleItemSelection={this.handleConditionSelection}
						selectedIndex={this.state.conditionIndex} 
					/>
				</div>
				<div style={{flexGrow: '100', marginLeft: '10px'}}>
					<FilterValue holder={this} />
				</div>
			</div>
		);
	}
}

function FilterValue(props) {
	let holder = props.holder;
	let cond = holder.conditions[ holder.state.conditionIndex ];
	if( cond.id == 'include' || cond.id == 'notInclude') {
		return <FilterValueObjectList filter={holder.props.filter} handleFilterChanged={holder.handleFilterChanged} />
	} else {
		return null;
	}
}

export default FilterLinked;
