import React from 'react';
import styles from '../../components.module.css';

import { getCurrentResource, findByKey } from '../../../util/tools';

class TabRowColumns extends React.Component {

	/*
		props
			rateView
			queryOptions
				...
				cardColumn
				listColumn
	*/	
	constructor(props) {
		super(props);
		
		this.resource = getCurrentResource().components.rateview.conditions.TabRowColumns;
		
		this.state = {
			pageSize: props.queryOptions.pageSize,
			columns: props.queryOptions.listColumn,
			cardColumn: props.queryOptions.cardColumn,
			selectedIndex: -1,
		}
	}

	handlePageSizeChange = (e) => {
		let value = e.target.value;
		if( value ) {
			this.props.queryOptions.pageSize = value;
			this.setState({pageSize: value})
		}
	}
	handleCardColumnChange = (e) => {
		this.props.queryOptions.cardColumn = e.target.value;
		this.setState({cardColumn: e.target.value})
	}
	handleRowClick = (e, index) => {
		if( index == this.state.selectedIndex ) {
			if( e.ctrlKey )
				this.setState({selectedIndex: -1})
		} else {
			this.setState({selectedIndex: index})
		}
	}
	handleCheckBoxClick = (event, key, index) => {
		let columns = this.state.columns;
		columns[index][key] = event.target.checked;
		if( key == 'visible' && ! event.target.checked) {
			columns[index].calculateSum = false;
			columns[index].calculateAvg = false;
			columns[index].calculateMin = false;
			columns[index].calculateMax = false;
		} 
		this.setState({columns: columns});
	}
	moveRowUp = () => {
		let columns = this.state.columns;
		let index = this.state.selectedIndex;
		if( index <= 0 )
			return;
		let col = columns[index];
		columns[index] = columns[index-1];
		columns[index-1] = col;
		this.setState({columns: columns, selectedIndex: index-1});
	}
	moveRowDown = () => {
		let columns = this.state.columns;
		let index = this.state.selectedIndex;
		if( index < 0 || index >= columns.length-1)
			return;
		let col = columns[index];
		columns[index] = columns[index+1];
		columns[index+1] = col;
		this.setState({columns: columns, selectedIndex: index+1});
	}
	
	render() {
		let cardColumnsOptionHtml = this.state.columns.map((column, index) => {
			return (<option key={index} value={column.id}>{column.title}</option>)
		})
		let columnsHtml = this.state.columns.map((column, index) => {
			let styleSelected = {backgroundColor: 'lightBlue'};
			let styleRegular = {backgroundColor: 'white'};
			let style = (index == this.state.selectedIndex) ? styleSelected : styleRegular;
			let colModel = findByKey(this.props.rateView.formInfo.listColumn, 'id', column.id);
			let tooltipText = colModel.tooltip;
			let tooltipHtml = (tooltipText) ? <span className={styles.tooltiptext}>{tooltipText}</span> : null;
			return (
				<tr key={index} style={style} onClick={ (e) => {this.handleRowClick(e, index)} }>
					<td>
						<div className={styles.divWithTooltip}>
							{column.title}
							{tooltipHtml}
						</div>
					</td>
					<td style={{textAlign: 'center'}}>
						<input type='checkbox' checked={column.visible} onClick={ (e) => {this.handleCheckBoxClick(e, 'visible', index)} }/>
					</td>
					<td style={{textAlign: 'center'}}>
						{ colModel.calculateSum ? 
							<input type='checkbox' checked={column.calculateSum} 
								onClick={ (e) => {this.handleCheckBoxClick(e, 'calculateSum', index)} }
							/>  : null
						}
					</td>
					<td style={{textAlign: 'center'}}>
						{ colModel.calculateAvg ? 
							<input type='checkbox' checked={column.calculateAvg} 
								onClick={ (e) => {this.handleCheckBoxClick(e, 'calculateAvg', index)} }
							/>  : null
						}
					</td>
					<td style={{textAlign: 'center'}}>
						{ colModel.calculateMin ? 
							<input type='checkbox' checked={column.calculateMin} 
								onClick={ (e) => {this.handleCheckBoxClick(e, 'calculateMin', index)} }
							/>  : null
						}
					</td>
					<td style={{textAlign: 'center'}}>
						{ colModel.calculateMax ? 
							<input type='checkbox' checked={column.calculateMax} 
								onClick={ (e) => {this.handleCheckBoxClick(e, 'calculateMax', index)} }
							/>  : null
						}
					</td>
				</tr>
			)
		}); 
		let colHeaderStyle = {
			padding: '5px', textAlign: 'center', fontWeight: 'bold'
		};
		return (
			<div style={{minHeight: '500px', padding: '10px'}}>
				<div>
					{this.resource.labelRowsOnPage} 
					<input type='number' value={this.state.pageSize} 
						onChange={this.handlePageSizeChange} 
						style={{width: '70px', marginLeft: '5px'}}
					/>
					<span style={{marginLeft: '20px', marginRight: '5px'}}>
						{this.resource.labelPresentationColumn}
					</span>
					<select value={this.state.cardColumn} onChange={this.handleCardColumnChange}>
						<option value=''></option>
						{cardColumnsOptionHtml}
					</select>
				</div>
				<div style={{display: 'flex', marginTop: '20px'}}>
					<table border='1'>
						<thead>
							<tr>
								<td style={colHeaderStyle}>{this.resource.colTitleColumn}</td>
								<td style={colHeaderStyle}>{this.resource.colTitleVisible}</td>
								<td style={colHeaderStyle}>{this.resource.colTitleSum}</td>
								<td style={colHeaderStyle}>{this.resource.colTitleAvg}</td>
								<td style={colHeaderStyle}>{this.resource.colTitleMin}</td>
								<td style={colHeaderStyle}>{this.resource.colTitleMax}</td>
							</tr>
						</thead>
						<tbody>
							{columnsHtml}
						</tbody>
					</table>
					<div style={{marginLeft: '20px'}}>
						<div>
							<button type='button' style={{width: '100px'}} onClick={this.moveRowUp}>{this.resource.buttonMoveUp}</button>
						</div>
						<div style={{marginTop: '10px'}}>
							<button type='button' style={{width: '100px'}} onClick={this.moveRowDown}>{this.resource.buttonMoveDown}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TabRowColumns;
