import React from 'react';
import stylesPlain from './plain.module.css';

import Field from './Field';

import { getDefinedValue, getDefinedItem, getCurrentResource } from '../../util/tools';
import { IET_SET_VALUE } from '../../util/realm/instance';

class FieldString extends Field {

	refInput = null;
	cursorStart = 0;
	cursorEnd = 0;

	/*
		multiline
		rows
		cols
		inputType // text, password
		autoComplete // on, off
		validator {
			isValueValid - function
			validBackground
			invalidBackground
		}
		styleInput !!! Obsolete
		styleDiv   !!! Obsolete
		styles
			divMain
			input
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.plain.FieldString;
		this.refInput = React.createRef();
		this.state.value = this.field ? getDefinedValue( this.field.value, '') : '';
	}
	completeComponentDidUpdate(prevProps, prevState, doInit) {
		if ( doInit ) {
			this.setState({ value: getDefinedItem( this, ['field', 'value'], '') });
		}
		this.setCursorPositions();
	}
	
	setCursorPositions = () => {
		//reset the cursor position for input
		if( this.refInput.current ) {
			this.refInput.current.selectionStart = this.cursorStart;
			this.refInput.current.selectionEnd = this.cursorEnd;
		}
	};
	handleInstanceChange = async (event) => {
		if( event.eventType == IET_SET_VALUE && event.field == this.field) {
			this.setState({ value: event.newValue });
		}
	}
	handleValueChange = e => {
		let value = e.target.value;
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
		if( this.props.validator ) {
			
		}
		this.props.instance.setFieldValue( this.field, value )
	}
	handleOnFocus = e => {
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
	}
	handleOnClick = e => {
		this.cursorStart = e.target.selectionStart;
		this.cursorEnd = e.target.selectionEnd;
	}
	goToUrl = () => {
		let value = this.state.value;
		if( value ) {
			if( ! value.startsWith("http") )
				value = "http://" + value;
			window.open( value );
		}
	}
	
	render() {
		
		if( this.field == null ) {
			return this.createDivUnknownField();
		}
		
		let multiline = getDefinedValue( this.props.multiline, false);
		let readOnly = getDefinedValue( this.props.readOnly, false);
		let isURL = this.field != null && this.field.model.isURL;

		let inputStyle = Object.assign({}, 
				getDefinedValue(this.props.styleInput, {}), // Obsolete 
				getDefinedItem(this.props, ['styles', 'input']));
		
		if( multiline ) {
			let rows = getDefinedValue( this.props.rows, 4);
			let cols = getDefinedValue( this.props.cols, 30);
			return (
				<textarea ref={this.refInput}
					className={stylesPlain.textArea} style={inputStyle} rows={rows} cols={cols} 
					readOnly={readOnly} value={this.state.value} onChange={this.handleValueChange}
				/>
			);
		}
		
		if( isURL ) {
			let divMainStyle = Object.assign({}, 
					getDefinedValue(this.props.styleDiv, {}), // Obsolete 
					getDefinedItem(this.props, ['styles', 'divMain']),
					{display: 'flex', alignItems: 'center'});
			if( readOnly ) {
				let aHtml = null;
				if( this.state.value )
					aHtml = <a href={this.state.value}>{this.state.value}</a>;
				else
					aHtml = <span style={{fontStyle: 'italic', color: 'rgb(64,64,64)'}}>{this.resource.valueNotDefined}</span>; 
				return (
					<div style={divMainStyle}>
						{this.createLabel()}
						{aHtml}
					</div>
				)
			}
			inputStyle.flexGrow = '1';
			//inputStyle.padding = '3px';
			return (
				<div style={divMainStyle}>
					{this.createLabel()}
					<input type='text' ref={this.refInput} className={stylesPlain.inputFieldString} 
						style={inputStyle}
						value={this.state.value} onChange={ this.handleValueChange}
					/>
					<button type='button' style={{marginLeft: '10px', height: '30px'}} onClick={this.goToUrl}
						title={this.resource.buttonGoToUrlTooltip}
					>
						{this.resource.buttonGoToUrl}
					</button>
				</div>
			);
		}
		
		let type = getDefinedValue( this.props.inputType, 'text');
		let autoComplete = getDefinedValue( this.props.autoComplete, 'on');
		
		return (
			<React.Fragment>
				{this.createLabel()}
				<input type={type} ref={this.refInput} className={stylesPlain.inputFieldString} style={inputStyle} 
					autoComplete={autoComplete} readOnly={readOnly}
					value={this.state.value} onChange={ this.handleValueChange} 
					onFocus={this.handleOnFocus} onClick={this.handleOnClick}
				/>
			</React.Fragment>
		);
	}
}

export default FieldString;
