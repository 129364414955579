import config from '../config/config';
import { 
	FORM_AUTHORITY_AUTHOR_TABLE,
	FORM_AUTHORITY_PUBLISHER_TABLE,
	FORM_BOOK_TABLE,
	FORM_CLASSIFIER_TABLE,
	FORM_CONFIRMATION_TABLE,
	FORM_CONTACT_TABLE,
	FORM_DERIVATIVE_TABLE,
	FORM_DIGITAL_STORE_TABLE,
	FORM_EBOOK_BANK_TABLE,
	FORM_EBOOK_PAYMENT_TABLE,
	FORM_EBOOK_REPORT_TABLE,
	FORM_EBOOK_REPORT_ITEM_TABLE,
	FORM_EBOOK_SUPPLIER_TABLE,
	FORM_EBOOK_TERMINAL_TABLE,
	FORM_ESTIMATION_TABLE,
	FORM_EVENT_TABLE,
	FORM_HONOR_TABLE,
	FORM_LIBRARY_TABLE,
	FORM_MESSAGE_TABLE,
	FORM_OFFER_TABLE,
	FORM_ORDER_TABLE,
	FORM_ORGANIZATION_TABLE,
	FORM_LOCATION_TABLE,
	FORM_PROCUREMENT_TABLE,
	FORM_PRODUCT_TABLE,
	FORM_PROVIDER_TABLE,
	FORM_TOPIC_TABLE,
	FORM_USER_GROUP_TABLE,
	FORM_USER_TABLE,

	FORM_AUTHORITY_AUTHOR_FT,
	FORM_AUTHORITY_PUBLISHER_FT,
	FORM_BOOK_FT,
	FORM_BOOK_PROD_FT,
	FORM_CLASSIFIER_FT,
	FORM_CONFIRMATION_FT,
	FORM_CONTACT_FT,
	FORM_DERIVATIVE_FT,
	FORM_DIGITAL_STORE_FT,
	FORM_ESTIMATION_FT,
	FORM_EVENT_FT,
	FORM_HONOR_FT,
	FORM_LIBRARY_FT,	
	FORM_MESSAGE_FT,	
	FORM_OFFER_FT,
	FORM_ORDER_FT,
	FORM_ORGANIZATION_FT,
	FORM_LOCATION_FT,
	FORM_PROCUREMENT_FT,
	FORM_PRODUCT_FT,
	FORM_PROVIDER_FT,
	FORM_TOPIC_FT,
	FORM_USER_FT,
	FORM_USER_GROUP_FT,
	
	FORM_AUTHORITY_AUTHOR_PLAIN, 
	FORM_AUTHORITY_PUBLISHER_PLAIN, 
	FORM_BOOK_PLAIN,
	FORM_BOOK_SPECIAL_PLAIN,
	FORM_EBOOK_REPORT_PLAIN,
	FORM_EBOOK_SUPPLIER_PLAIN,
	FORM_OFFER_PLAIN, 
	FORM_PRODUCT_PLAIN,
} from './consts/forms';

export const ROOT_PATH = `${config.PATH}/`;
export const HELLO_PATH = `${config.PATH}/hello`;
export const ELIBRI_PATH = `${config.PATH}/elibri`;
export const OBJECT_MASTER_PATH = `${config.PATH}/object_master`;
export const BOM_MESSAGE_PATH = `${config.PATH}/message`;
export const PROFILE_PATH = `${config.PATH}/profile`;
export const DBMANAGER_PATH = `${config.PATH}/dbmanager`;
export const SYSINFO_PATH = `${config.PATH}/sysinfo`;

export const AUTHORITY_AUTHOR_TABLE_PATH = `${config.PATH}/${FORM_AUTHORITY_AUTHOR_TABLE}`;
export const AUTHORITY_PUBLISHER_TABLE_PATH = `${config.PATH}/${FORM_AUTHORITY_PUBLISHER_TABLE}`;
export const BOOK_TABLE_PATH = `${config.PATH}/${FORM_BOOK_TABLE}`;
export const CLASSIFIER_TABLE_PATH = `${config.PATH}/${FORM_CLASSIFIER_TABLE}`;
export const CONFIRMATION_TABLE_PATH = `${config.PATH}/${FORM_CONFIRMATION_TABLE}`;
export const CONTACT_TABLE_PATH = `${config.PATH}/${FORM_CONTACT_TABLE}`;
export const DERIVATIVE_TABLE_PATH = `${config.PATH}/${FORM_DERIVATIVE_TABLE}`;
export const DIGITAL_STORE_TABLE_PATH = `${config.PATH}/${FORM_DIGITAL_STORE_TABLE}`;
export const EBOOK_BANK_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_BANK_TABLE}`;
export const EBOOK_PAYMENT_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_PAYMENT_TABLE}`;
export const EBOOK_REPORT_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_REPORT_TABLE}`;
export const EBOOK_REPORT_ITEM_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_REPORT_ITEM_TABLE}`;
export const EBOOK_SUPPLIER_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_SUPPLIER_TABLE}`;
export const EBOOK_TERMINAL_TABLE_PATH = `${config.PATH}/${FORM_EBOOK_TERMINAL_TABLE}`;
export const ESTIMATION_TABLE_PATH = `${config.PATH}/${FORM_ESTIMATION_TABLE}`;
export const EVENT_TABLE_PATH = `${config.PATH}/${FORM_EVENT_TABLE}`;
export const HONOR_TABLE_PATH = `${config.PATH}/${FORM_HONOR_TABLE}`;
export const LIBRARY_TABLE_PATH = `${config.PATH}/${FORM_LIBRARY_TABLE}`;
export const MESSAGE_TABLE_PATH = `${config.PATH}/${FORM_MESSAGE_TABLE}`;
export const OFFER_TABLE_PATH = `${config.PATH}/${FORM_OFFER_TABLE}`;
export const ORDER_TABLE_PATH = `${config.PATH}/${FORM_ORDER_TABLE}`;
export const ORGANIZATION_TABLE_PATH = `${config.PATH}/${FORM_ORGANIZATION_TABLE}`;
export const LOCATION_TABLE_PATH = `${config.PATH}/${FORM_LOCATION_TABLE}`;
export const PROCUREMENT_TABLE_PATH = `${config.PATH}/${FORM_PROCUREMENT_TABLE}`;
export const PRODUCT_TABLE_PATH = `${config.PATH}/${FORM_PRODUCT_TABLE}`;
export const PROVIDER_TABLE_PATH = `${config.PATH}/${FORM_PROVIDER_TABLE}`;
export const TOPIC_TABLE_PATH = `${config.PATH}/${FORM_TOPIC_TABLE}`;
export const USER_GROUP_TABLE_PATH = `${config.PATH}/${FORM_USER_GROUP_TABLE}`;
export const USER_TABLE_PATH = `${config.PATH}/${FORM_USER_TABLE}`;

export const AUTHORITY_AUTHOR_PATH = `${config.PATH}/${FORM_AUTHORITY_AUTHOR_FT}`;
export const AUTHORITY_PUBLISHER_PATH = `${config.PATH}/${FORM_AUTHORITY_PUBLISHER_FT}`;
export const BOOK_PATH = `${config.PATH}/${FORM_BOOK_FT}`;
export const BOOK_PROD_PATH = `${config.PATH}/${FORM_BOOK_PROD_FT}`;
export const CLASSIFIER_PATH = `${config.PATH}/${FORM_CLASSIFIER_FT}`;
export const CONFIRMATION_PATH = `${config.PATH}/${FORM_CONFIRMATION_FT}`;
export const CONTACT_PATH = `${config.PATH}/${FORM_CONTACT_FT}`;
export const DERIVATIVE_PATH = `${config.PATH}/${FORM_DERIVATIVE_FT}`;
export const DIGITAL_STORE_PATH = `${config.PATH}/${FORM_DIGITAL_STORE_FT}`;
export const ESTIMATION_PATH = `${config.PATH}/${FORM_ESTIMATION_FT}`;
export const EVENT_PATH = `${config.PATH}/${FORM_EVENT_FT}`;
export const HONOR_PATH = `${config.PATH}/${FORM_HONOR_FT}`;
export const LIBRARY_PATH = `${config.PATH}/${FORM_LIBRARY_FT}`;
export const MESSAGE_PATH = `${config.PATH}/${FORM_MESSAGE_FT}`;
export const OFFER_PATH = `${config.PATH}/${FORM_OFFER_FT}`;
export const ORDER_PATH = `${config.PATH}/${FORM_ORDER_FT}`;
export const ORGANIZATION_PATH = `${config.PATH}/${FORM_ORGANIZATION_FT}`;
export const LOCATION_PATH = `${config.PATH}/${FORM_LOCATION_FT}`;
export const PROCUREMENT_PATH = `${config.PATH}/${FORM_PROCUREMENT_FT}`;
export const PRODUCT_PATH = `${config.PATH}/${FORM_PRODUCT_FT}`;
export const PROVIDER_PATH = `${config.PATH}/${FORM_PROVIDER_FT}`;
export const TOPIC_PATH = `${config.PATH}/${FORM_TOPIC_FT}`;
export const USER_GROUP_PATH = `${config.PATH}/${FORM_USER_GROUP_FT}`;
export const USER_PATH = `${config.PATH}/${FORM_USER_FT}`;

export const AUTHORITY_AUTHOR_PLAIN_PATH = `${config.PATH}/${FORM_AUTHORITY_AUTHOR_PLAIN}`;
export const AUTHORITY_PUBLISHER_PLAIN_PATH = `${config.PATH}/${FORM_AUTHORITY_PUBLISHER_PLAIN}`;
export const BOOK_PLAIN_PATH = `${config.PATH}/${FORM_BOOK_PLAIN}`;
export const BOOK_PLAIN_SPECIAL_PATH = `${config.PATH}/${FORM_BOOK_SPECIAL_PLAIN}`;
export const EBOOK_REPORT_PLAIN_PATH = `${config.PATH}/${FORM_EBOOK_REPORT_PLAIN}`;
export const EBOOK_SUPPLIER_PLAIN_PATH = `${config.PATH}/${FORM_EBOOK_SUPPLIER_PLAIN}`;
export const OFFER_PLAIN_PATH = `${config.PATH}/${FORM_OFFER_PLAIN}`;
export const PRODUCT_PLAIN_PATH = `${config.PATH}/${FORM_PRODUCT_PLAIN}`;

export const CONVERTER_PATH = `${config.PATH}/converter`;
export const BOOKSUPPLY_PATH = `${config.PATH}/booksupply`;
