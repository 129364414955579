import React from 'react';

class TableRow extends React.Component {

	/*
		tableData
		rateView
		tableBody
		index
		row
	*/
	constructor(props) {
		super(props);
		var selfIndex = -1;
		for( let i=0; i < props.tableBody.listComponentTableRow.length; i++ ) {
			var c = props.tableBody.listComponentTableRow[i];
			if( c.props.index == props.index ) {
				selfIndex = i;
				break;
			}
		}
		if( selfIndex < 0 )
			props.tableBody.listComponentTableRow.push( this );
		else
			props.tableBody.listComponentTableRow[selfIndex] = this;
		this.state = {
			marked: props.tableData[ props.index ].checked 
		};
	}

	handleCheckboxChange = (event) => {
		//const {name, checked} = event.target;
		//this.props.tableData[name].checked = checked;
		this.setMarked( event.target.checked );
	}
	changeMarked = () => {
	}
	setMarked = (marked) => {
		this.props.tableData[ this.props.index ].checked = marked;
		this.setState({marked: marked});
		this.props.rateView.handleRowSelectionChange(); 
	}

	render() {
		let trCardHtml = null;
		if( this.props.row.cardCell ) {
			let cell = this.props.row.cardCell;
			trCardHtml = (
				<tr style={{backgroundColor: 'rgb(240,240,240)', fontWeight: 'bold'}}>
					<td colspan={this.props.row.row.length+1}>
						<Cell key={'card'} cell={cell} deleted={this.props.row.deleted} />
					</td>
				</tr>
			)
		}
		if( this.props.row.deleted ) {
			return (
				<React.Fragment>
					{trCardHtml}
					<tr>
						<td style={{color: 'red', fontWeight: 'bold', textAlign: 'center', valign: 'center'}}>
							X
						</td>
						{
							this.props.row.row.map((cell,index) => {
								return <Cell key={index} cell={cell} deleted={true} />
							})
						}
					</tr>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					{trCardHtml}
					<tr>
						<td style={{textAlign: 'center'}}>
							<input type="checkbox" name={this.props.index} onChange={this.handleCheckboxChange} checked={this.state.marked} />
						</td>
						{
							this.props.row.row.map((cell,index) => {
								return <Cell key={index} cell={cell} deleted={false} />
							})
						}
					</tr>
				</React.Fragment>
			);
		}
	}
}

function Cell(props) {
	var cell = props.cell;
	if( cell.type == 'img' ) {
		if( cell.text ) {
			var style = {marginLeft: 'auto', marginRight: 'auto'}
			var w = cell.imageWidth;
			var h = cell.imageHeight;
			if( w && h )
				var cellBodyHtml = <img referrerPolicy='no-referrer' src={cell.text} height={h} width={w} style={style} />
			else if( w && ! h )
				var cellBodyHtml = <img referrerPolicy='no-referrer' src={cell.text} width={w} style={style} />
			else if( !w && h )
				var cellBodyHtml = <img referrerPolicy='no-referrer' src={cell.text} height={h} style={style} />
			else 
				var cellBodyHtml = <img referrerPolicy='no-referrer' src={cell.text} style={style} />
		} else {
			var cellBodyHtml = null;
		}
	} else if( cell.type == 'html' ) {
		var cellBodyHtml = ( <span dangerouslySetInnerHTML={{__html: cell.text}} /> );
		var cellBodyHtml = cell.text;
	} else if( cell.url ) {
		var cellBodyHtml = (<a href={cell.text} target='_blank'>{cell.text}</a>);
	} else {
		var cellBodyHtml = cell.text;
	}
	if( props.deleted ) {
		return (
			<td valign="top">
				<div style={{color: 'lightgrey'}}>{cellBodyHtml}</div>
			</td>
		);
	} else {
		var cssStyle = JSON.parse( props.cell.cssStyle );
		if( cell.type == 'img' ) {
			if( ! cssStyle.textAlign )
				cssStyle.textAlign = 'center';
		}
		return (
			<td valign="top">
				<div style={cssStyle}>{cellBodyHtml}</div>
			</td>
		);
	}
}

export default TableRow;
