import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource } from '../../util/tools';
import { canPerformAction } from '../../util/localStorage';
import { ACTION_EBOOK_REPORT_EXPORT, ACTION_EBOOK_REPORT_IMPORT } from '../../util/consts/actions';
import { FORM_EBOOK_REPORT_TABLE } from '../../util/consts/forms';
import { 
	performApiDownloadRequest, performApiRequestForm, performApiRequest, 
	ACTION_EBOOK_REPORT_EXPORT_API_URL, ACTION_EBOOK_REPORT_IMPORT_API_URL,
	ACTION_EBOOK_REPORT_IMPORT_LOCAL_API_URL 
} from '../../util/restapi';

import iconImport from '../../assets/icons/png24x24/get_message.png';
import iconExport from '../../assets/icons/png24x24/send_message.png';

const MODAL_MODE_ACTIVE = 'a';
const MODAL_MODE_WAIT = 'w';
const MODAL_MODE_COMPLETE = 'c';
const MODAL_MODE_ERROR = 'e';

const IMPORT_MODE_NETWORK = 'network';
const IMPORT_MODE_LOCAL = 'local';

class EBookReportListPage extends Component {
	
	constructor(props) {
		super(props);
		this.refExportModal = React.createRef();
		this.refImportModal = React.createRef();
		this.state = {
			isExportModalOpen: false,
			isImportModalOpen: false,
		}

		this.resource = getCurrentResource().pages.tableform.EBookReportList;
		let paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_EBOOK_REPORT_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_EBOOK_REPORT_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null,
			//hideButtonAdd: true,
			//hideButtonEdit: true,
			hideButtonMergeInstances: true,
			//hideButtonChangeOwner: true,
			//hideButtonFieldUpdate: true,
			hideButtonGlobalIdUpdate: true,
			//hideButtonDelete: true,
			//hideButtonShowHtml: true,
			//hideButtonHtml: true,
			//hideButtonPdf: true,
			//hideButtonXml: true,
			//hideButtonTransition: true,
			//hideButtonUshExport: true,
			//hideButtonUshImport: true,
			controlPanelTools: this.controlPanelTools(),
		};
	}
	controlPanelTools = () => {
		return (
			<div style={{textAlign: 'left', marginLeft: '20px'}}>
				<ControlPanelButtonExport holder={this} />
				<ControlPanelButtonImport holder={this} />
			</div>			
		);
	}

	openExportModal = () => {
		if( ! this.rvContext.data )
			return;
		
		let listInstanceId = [];
		for( let row of this.rvContext.data ) {
			if( row.checked ) {
				listInstanceId.push( row.id );
			}
		}
		if( listInstanceId.length == 0 )
			return;
		
		this.refExportModal.current.initModal(listInstanceId);
		this.setState({ isExportModalOpen: true});	
	}
	openImportModal = () => {
		this.refImportModal.current.initModal();
		this.setState({ isImportModalOpen: true });	
	}
	closeExportModal = () => {
		this.setState({ isExportModalOpen: false });	
	}
	closeImportModal = () => {
		this.setState({ isImportModalOpen: false });	
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
				<ExportModal ref={this.refExportModal} page={this} 
					isModalOpen={this.state.isExportModalOpen} 
					close={this.closeExportModal} 
				/>
				<ImportModal ref={this.refImportModal} page={this} 
					isModalOpen={this.state.isImportModalOpen} 
					close={this.closeImportModal} 
				/>
			</React.Fragment>
		)
	}
	
}

class ExportModal extends React.Component {

	constructor(props) {
		super(props);
		this.listInstanceId = [];
		this.errorMessage = '';
		this.errorLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
		};
	}
	initModal = (listInstanceId) => {
		this.listInstanceId = listInstanceId;
		this.errorMessage = '';
		this.errorLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			fileName: '',
		});
	}

	handleInputEdit = (e) => {
		const { name, value } = e.target;
		if( name == 'filename' )
			this.setState({fileName: value});
	};
	startExport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})
		
		let exportOptions = {
			testMode: false,
			listInstanceId: this.listInstanceId
		};
		let response = await performApiDownloadRequest(
			ACTION_EBOOK_REPORT_EXPORT_API_URL, exportOptions, 'text/xml');
		if( response.success ) {
			let fileName = this.state.fileName;
			if( ! fileName )
				fileName = 'export_ebook_report.xml';
			const blobUrl = window.URL.createObjectURL(response.blob);
			const link = document.createElement('a');
			link.href = blobUrl;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			this.setState({modalMode: MODAL_MODE_ACTIVE})
			this.props.page.closeExportModal();
		} else {
			this.errorMessage = response.message; 
			this.errorLog = response.log;
			this.setState({modalMode: MODAL_MODE_ERROR})
		}
	}
	tryAgain = () => {
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
		});
	}

	render() {
		let resource = this.props.page.resource;
		let modalBodyHtml;
		if( this.state.modalMode == MODAL_MODE_ERROR ) {
			modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}}>
						{this.errorMessage}
					</div>
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			let divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			modalBodyHtml = (
				<React.Fragment>
					<div style={localStyles.divParameters}>
						<div>
							{resource.modallabelFile} 
							<input type="text" name="filename" style={{marginLeft: '10px', width: '400px'}}
								value={this.state.fileName} onChange={ this.handleInputEdit}
							/>
						</div>
					</div>
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonExportTooltip}
							onClick={this.startExport} disabled={this.state.modalMode == MODAL_MODE_WAIT}
						>
							{resource.buttonExport}
						</button>
					</div>
				</React.Fragment>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1}
			>
				<div>
					<div style={localStyles.divHeader}>
						{resource.modalExportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

class ImportModal extends React.Component {

	constructor(props) {
		super(props);
		this.resource = props.page.resource;
		this.fileImportRef = React.createRef();
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.state = {
			modalMode: MODAL_MODE_ACTIVE,
			importMode: IMPORT_MODE_NETWORK,
			fileName: this.resource.textSelectFile,
			filePath: '',
		}
	}
	initModal = () => {
		this.file = null;
		this.resultMessage = '';
		this.resultLog = '';
		this.setState({
			modalMode: MODAL_MODE_ACTIVE,
			importMode: IMPORT_MODE_NETWORK,
			fileName: this.resource.textSelectFile,
			filePath: '',
		});
	}
	tryAgain = () => {
		this.setState({	modalMode: MODAL_MODE_ACTIVE});
	}

	onImportModeChange = (event) =>	{
		this.setState({ importMode: event.target.value });
	}
	onFilePathChange = (event) =>	{
		this.setState({ filePath: event.target.value });
	}
	handleFileSelect = (e) => {
		let files = e.target.files;
		if( files && files.length > 0 ) {
			this.file = files[0];
			this.setState({fileName: this.file.name})
		}
	}	

	startImport = async () => {
		this.setState({modalMode: MODAL_MODE_WAIT})

		let response;
		if( this.state.importMode == IMPORT_MODE_NETWORK ) {
			const form = new FormData();
			form.append('format', 'xml');
			form.append('file', this.file);
			response = await performApiRequestForm(ACTION_EBOOK_REPORT_IMPORT_API_URL, form);
		} else if( this.state.importMode == IMPORT_MODE_LOCAL ) {
			let importOptions = {
				filePath: this.state.filePath
			}
			response = await performApiRequest( ACTION_EBOOK_REPORT_IMPORT_LOCAL_API_URL, importOptions );
		} else {
			response = {success: false, message: 'Unknown import mode'};
		}
		
		if( response.success ) {
			this.resultMessage = response.message;
			this.resultLog = response.log;
			if( this.resultMessage == '' ) {
				this.resultMessage = this.resource.messageImportComplete;
			} 
		} else {
			this.resultMessage = response.message; 
			this.resultLog = response.log;
		}
		this.setState({modalMode: MODAL_MODE_COMPLETE})
	}

	render() {
		let resource = this.resource;
		let modalBodyHtml;
		let holder = this.props.page;
		if( this.state.modalMode == MODAL_MODE_COMPLETE ) {
			let divAdditionalMessage = null;
			if( this.resultLog ) {
				divAdditionalMessage = (
					<div style={{marginLeft: 'auto', marginRight: 'auto', marginBottom: '30px', width: '95%'}}>
						<textarea style={{width: '100%'}} rows="8" readOnly={true}>
							{this.resultLog}
						</textarea>
					</div>		
				);
			}
			modalBodyHtml = (
				<React.Fragment>
					<div style={{color: 'red', textAlign: 'center', padding: '30px', fontSize: '16pt'}} 
						dangerouslySetInnerHTML={{__html: this.resultMessage}}
					/>
					{divAdditionalMessage}
					<div style={localStyles.divButtons}>
						<button type='button' onClick={this.tryAgain} >
							{resource.buttonTryAgain}
						</button>
					</div>
				</React.Fragment>
			);
		} else {
			let divFileHtml = null 
			if( this.state.importMode == IMPORT_MODE_NETWORK ) {
				divFileHtml = (
					<div>
						{resource.labelFile}: 
						<span style={{marginLeft: '5px', marginRight: '10px'}}>{this.state.fileName}</span> 
						<button onClick={e => this.fileImportRef.current.click()}>...</button>
						<input ref={this.fileImportRef} type={'file'}  style={{display: 'none'}} 
							onChange={e => this.handleFileSelect(e)} 
						/>
					</div>
				);
			} else if( this.state.importMode == IMPORT_MODE_LOCAL ) {
				divFileHtml = (
					<div>
						{resource.labelFile}: <input type='text' size='40' value={holder.state.filePath} onChange={this.onFilePathChange} /> 
					</div>
				);
			}
			let buttonImportDisabled = this.state.modalMode == MODAL_MODE_WAIT || 
				(this.file == null && this.state.importMode == IMPORT_MODE_NETWORK);
			let divWaiting = this.state.modalMode == MODAL_MODE_WAIT ?
				(<div style={localStyles.divWaiting}>{resource.waiting}</div>) : null;  
			modalBodyHtml = (
				<div style={{margin: '15px'}}>
					<div style={{marginTop: '15px', marginBottom: '15px'}}>
						<select value={this.state.importMode} onChange={this.onImportModeChange}>
							<option value={IMPORT_MODE_NETWORK}>{resource.optionNetworkImport}</option>
							<option value={IMPORT_MODE_LOCAL}>{resource.optionLocalImport}</option>
						</select>
					</div>
					{divFileHtml}
					{divWaiting}
					<div style={localStyles.divButtons}>
						<button type='button' title={resource.buttonImportTooltip}
							onClick={this.startImport} disabled={buttonImportDisabled}
						>
							{resource.buttonImport}
						</button>
					</div>
				</div>
			);
		}
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center 
				styles={styleModal} closeOnOverlayClick={false} animationDuration={1} 
			>
				<div>
					<div style={localStyles.divHeader}>
						{this.resource.modalImportTitle}
					</div>
					{modalBodyHtml}
				</div>
			</Modal>
		);
	}
}

function ControlPanelButtonExport(props) {
	
	if( canPerformAction( ACTION_EBOOK_REPORT_EXPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openExportModal}
				 title={props.holder.resource.buttonExportTooltip} 
			>
				<img src={iconExport} />
			</button>			
		);
	} else {
		return null;
	}
}

function ControlPanelButtonImport(props) {
	
	if( canPerformAction( ACTION_EBOOK_REPORT_IMPORT ) ) {
		return (
			<button type='button' style={{marginLeft: '5px', width: '36px', height: '30px'}}
				 onClick={props.holder.openImportModal}
				 title={props.holder.resource.buttonImportTooltip} 
			>
				<img src={iconImport} />
			</button>			
		);
	} else {
		return null;
	}
}

const styleModal = {
	modal: {
		background: '#ffffff',
		padding: '0px',
		borderRadius: '21px',
		maxWidth: '500px',
		width: '100%',
	},
	closeButton: {
		top: '10px',
		right: '10px',
		fill: '#FF0000',
		outline: 'none',
	},
};

const localStyles = {
	divHeader: {
		fontSize: '16pt',
		textAlign: 'center',
		padding: '10px',
		borderRadius: '21px 21px 0 0',
		backgroundColor: '#F0F0F0'
	},
	divParameters: {
		marginTop: '10px',
		padding: '10px'
	},
	divWaiting: {
		textAlign: 'center',
		padding: '7px'
	},
	divButtons: {
		marginTop: '7px',
		padding: '10px',
		marginRight: '15px',
		textAlign: 'right'
	}
};

export default EBookReportListPage;
