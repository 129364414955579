import React, { Component } from 'react';

import NavBar from '../../components/navigation/NavBar';
import RateView from '../../components/rateview/RateView';

import { getCurrentResource } from '../../util/tools';
import { FORM_EBOOK_TERMINAL_TABLE } from '../../util/consts/forms';

class EBookTerminalListPage extends Component {
	
	constructor(props) {
		super(props);

		this.resource = getCurrentResource().pages.tableform.EBookTerminalList;
		var paramCacheId = props.match.params.param;

		this.rvContext = {
			form: FORM_EBOOK_TERMINAL_TABLE,
			data: []
		};
		this.rvOptions = {
			form: FORM_EBOOK_TERMINAL_TABLE,
			formTitle: this.resource.formTitle,
			paramCacheId: paramCacheId,
			transition: null,
			hideButtonAdd: true,
			hideButtonEdit: true,
			hideButtonMergeInstances: true,
			hideButtonChangeOwner: true,
			hideButtonFieldUpdate: true,
			hideButtonGlobalIdUpdate: true,
			hideButtonDelete: true,
			hideButtonShowHtml: true,
			//hideButtonHtml: true,
			hideButtonPdf: true,
			hideButtonXml: true,
			//hideButtonTransition: true,
			hideButtonUshExport: true,
			hideButtonUshImport: true,
		};
	}

	render() {
		return (
			<React.Fragment>
				<NavBar app={this.props.app} />
				<RateView rvContext={this.rvContext} rvOptions={this.rvOptions} app={this.props.app}  />
			</React.Fragment>
		)
	}
	
}

export default EBookTerminalListPage;
