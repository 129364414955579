import React, { createRef } from 'react';

import ButtonIconText from '../../button/ButtonIconText';
import ViewConditionsModal from './ViewConditionsModal';

import { getCurrentResource, findByKey } from '../../../util/tools';
import { setRateViewProfile } from '../../../util/localStorage';

import { MODE_TREE } from '../RateView';

import gearIcon from '../../../assets/icons/png24x24/Gear.png';

class ButtonViewConditions extends React.Component {

	/*
		props
			rateView
	*/
	constructor(props) {
		super(props);
		this.resource = getCurrentResource().components.rateview.conditions.ButtonViewConditions;
		this.rateView = props.rateView;
		this.refModal = createRef();
		this.state = {
			isModalOpen: false
		}
	}
	
	openModal = () => {
		let rvQueryOptions = this.props.rateView.queryOptions;
		let dlgQueryOptions = {
			pageSize: this.props.rateView.rvProfile.pageSize,
			cardColumn: this.props.rateView.rvProfile.cardColumn,
			listColumn: [],
			listHardFilter: [],
			listActiveSorting: [...rvQueryOptions.listActiveSorting]
		}
		for(let col of this.props.rateView.rvProfile.listColumn) {
			let rvCol = findByKey(this.props.rateView.formInfo.listColumn, 'id', col.id);
			dlgQueryOptions.listColumn.push({
				id: col.id,
				title: rvCol.title,
				tooltip: rvCol.tooltip,
				visible: col.visible,
				calculateSum: col.calculateSum,
				calculateAvg: col.calculateAvg,
				calculateMin: col.calculateMin,
				calculateMax: col.calculateMax
			});
		}
		for(let f of rvQueryOptions.listHardFilter) {
			dlgQueryOptions.listHardFilter.push({
				filterModel: f.filterModel,
				title: f.title,
				tooltip: f.tooltip,
				conditionId: f.conditionId,
				listValue: [...f.listValue],
				listValueObject: [...f.listValueObject],
				active: f.active,
				bodyText: f.bodyText
			});
		}
		
		this.refModal.current.updateQueryOptions( dlgQueryOptions );
		this.setState({ isModalOpen: true });
	}
	closeModal = () => {
		this.setState({ isModalOpen: false }, this.doAfterCloseModal);
	}
	doAfterCloseModal = () => {
		if( this.refModal.current.modalSuccess ) {
			let rvProfile = this.rateView.rvProfile; 
			let dlgQueryOptions = this.refModal.current.state.queryOptions;
			let rvQueryOptions = this.props.rateView.queryOptions;

			rvProfile.pageSize = dlgQueryOptions.pageSize;
			rvProfile.cardColumn = dlgQueryOptions.cardColumn;
			this.rateView.currentPageSize = dlgQueryOptions.pageSize;
			this.rateView.query.pageSize = dlgQueryOptions.pageSize;
			rvQueryOptions.cardColumn = dlgQueryOptions.cardColumn;

			rvQueryOptions.listColumn = [];
			rvProfile.listColumn = [];
			for(let col of dlgQueryOptions.listColumn) {
				if( col.visible ) {
					rvQueryOptions.listColumn.push( {
						id: col.id,
						calculateSum: col.calculateSum,
						calculateAvg: col.calculateAvg,
						calculateMin: col.calculateMin,
						calculateMax: col.calculateMax
					} );
					rvProfile.listColumn.push( {
						id: col.id, visible: col.visible,
						calculateSum: col.calculateSum,
						calculateAvg: col.calculateAvg,
						calculateMin: col.calculateMin,
						calculateMax: col.calculateMax
					} );
				}
			}
			for(let col of dlgQueryOptions.listColumn) {
				if( ! col.visible ) {
					rvProfile.listColumn.push( {id: col.id, visible: col.visible} );
				}
			}
						
			rvQueryOptions.listHardFilter = [];
			let bodyText = '';
			for(let f of dlgQueryOptions.listHardFilter) {
				rvQueryOptions.listHardFilter.push({
					filterModel: f.filterModel,
					title: f.title,
					conditionId: f.conditionId,
					listValue: [...f.listValue],
					listValueObject: [...f.listValueObject],
					active: f.active,
					bodyText: f.bodyText
				});
				if( f.active ) {
					bodyText += f.bodyText + '\n';
				}
			}
			
			rvQueryOptions.listActiveSorting = [...dlgQueryOptions.listActiveSorting];
			rvProfile.listActiveSorting = [];
			for(let s of rvQueryOptions.listActiveSorting ) {
				rvProfile.listActiveSorting.push( {sortingId: s.sortingId, ascending: s.ascending} );
			}

			setRateViewProfile(this.rateView.formInfo.formId, rvProfile);	
			this.rateView.setState({hardFilterBodyText: bodyText})
			this.rateView.handleRefresh();
		}
	}
	
	render() {
		if(  this.rateView.state.tableTreeMode == MODE_TREE )
			return null;
		if( ! this.rateView.formInfo.dlgViewConditions.showButtonViewConditions )
			return null;
		return (
			<React.Fragment>
				<ButtonIconText 
					onClick={this.openModal}
					text={this.resource.text} 
					tooltip={this.resource.tooltip} icon={gearIcon}
				/>
				<ViewConditionsModal 
					ref={this.refModal}
					isModalOpen={this.state.isModalOpen} closeModal={this.closeModal} 
					rateView={this.props.rateView} holder={this} />
			</React.Fragment>
		);
	}
}

export default ButtonViewConditions;
