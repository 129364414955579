const ResourceEng = {
	App: {
		msgAccessDenied: 'Sorry. Not enough roghts for access'
	},
	components: {
		exim: {
			ClassifierImport: {
				buttonImport: "Import",
				buttonImportTooltip: "Import"
			},
			OnixExport: {
				buttonExport: "Export",
				buttonExportTooltip: "Export marked messages to Onix",
				msgSelectDataForExport: 'Select data for export',
				msgAlienObjectsSelected: 'There are alien selected objects'
			},
			OnixImport: {
				buttonImport: "Import",
				buttonImportTooltip: "Import ONIX messages",
			}
		},
		footer: {
			title: 'Ukranian book institute'
		},
		fulldescription: {
			FieldDateTime: {
			},
			FieldDecimal: {
			},
			FieldEnumeration: {
				labelUndefined: '<Undefined>'
			},
			FieldFile: {
				buttonSelectFile: 'Select file for saving',
				buttonDownloadFile: 'Download saved file',
				buttonDownloadFileAfterSave: 'Download will be granted after saving',
				buttonClearFile: 'Clear file'
			},
			FieldGroup: {
				buttonCollapse: 'Collapse',
				buttonCollapseTooltip: 'Collapse group of field',
				buttonExpand: 'Expand',
				buttonExpandTooltip: 'EXpand  group of field',
				buttonGoToRefTooltip: 'Go',
				buttonSelectTooltip: 'Link and copy fields',
				buttonLinkTooltip: 'Link',
				buttonClearTooltip: 'Clear',
				buttonSave: 'Save',
				buttonSaveTooltip: 'Save object to database'
			},
			FieldImage: {
				buttonClear: 'Clear',
				buttonClearTooltip: 'Clear image field',
				buttonSelect: 'Select',
				buttonSelectTooltip: 'Select image from file'
			},
			FieldInteger: {
			},
			FieldList: {
				buttonAdd: 'Add',
				buttonAddTooltip: 'Add object',
				buttonCollapse: 'Collapse',
				buttonCollapseTooltip: 'Collapse field list',
				buttonDelete: 'Delete',
				buttonDeleteTooltip: 'Delete field list',
				buttonExpand: 'Expand',
				buttonExpandTooltip: 'Expand field list',
				buttonMoveDown: 'Move down',
				buttonMoveDownTooltip: 'Move field down',
				buttonMoveUp: 'Move up',
				buttonMoveUpTooltip: 'Move field up'
			},
			FieldReference: {
				buttonClearTooltip: 'Clear reference',
				buttonSelect: 'Select',
				buttonSelectTooltip: 'Select referenced field',
				messageNotDefined: 'Not defined',
				messageAbsent: 'Object is absent'
			},
			FieldString: {
				buttonGo: 'Go',
				buttonGoTooltip: 'Go to reference'
			},
			PlainFullDescription: {
				messageSaved: 'Saved',
				messageInitForm: 'Init form...',
				messageInstanceIsAbsent: 'Sorry, instance is absent in database'
			},
			SystemData: {
				labelCard: 'Card', 
				labelClass: 'Class', 
				labelOwnerGroup: 'Owner (group)', 
				labelOwnerUser: 'Owner (user)',
				labelCreateUser: 'Create user', 
				labelCreateTime: 'Create time', 
				labelModifyUser: 'Modify user', 
				labelModifyTime: 'Modify time',
				title: 'System data'
			}
		},
		header: {
			Header: {
				title: 'Information system "Books on market"',
				
				msgInvalidLoginPassword: 'Invalid login and/or password',
				
				buttonLogin: 'Sign in',
				buttonLogout: 'Exit',
				buttonProfile: 'Profile',
				buttonProfileTooltip: 'Profile'
			}
		},
		login: {
			LoginForm: {
				title: 'Register',
				fieldNameLabel: 'Login',
				fieldNamePlaceholder: 'Login',
				fieldPasswordLabel: 'Password',
				fieldPasswordPlaceholder: 'Password',
				buttonSubmit: 'Sign in',
				buttonCancel: 'Cancel'
			}
		},
		message: {
			ConfirmMessageModal: {
				header: 'Attention',
				buttonYes: 'Yes',
				buttonNo: 'No'
			},
			InfoMessage: {
				defaultNotSuccessMessage: 'Error happened during action performing'
			}
		},
		navigation: {
			ButtonMode: {
				titleIcon: 'Icon',
				titleText: 'Text'
			},
			NavBar: {
				menuCatalog: 'Catalog',
				menuProducts: 'Products',
				menuSubjects: 'Subjects',
				menuEBook: 'eBook',
				menuAdmin: 'Admin',
				formDbmanagerTitle: 'DB Manager',
				formSystemInfoTitle: 'System info',
				formMainTitle: 'Main',
				formProfileTitle: 'Profile',
				tfAuthorityAuthorTitle: 'Author authorities',
				tfAuthorityPublisherTitle: 'Publisher authorities',
				tfBookTitle: 'Books',
				tfConfirmationTitle: 'Confirmations',
				tfContactTitle: 'Contacts',
				tfDerivativeTitle: 'Derivatives',
				tfDigitalStoreTitle: 'Digital stores',
				tfEstimationTitle: 'Estimations',
				tfEventTitle: 'Events',
				tfHonorTitle: 'Honors',
				tfLibraryTitle: 'Libraries',
				tfMessageTitle: 'Messages',
				tfOfferTitle: 'Offers',
				tfOrderTitle: 'Selected',
				tfOrganizationTitle: 'Organizations',
				tfPlaceTitle: 'Places',
				tfProcurementTitle: 'Procurements',
				tfProductTitle: 'Products',
				tfProviderTitle: 'Providers',
				tfTopicTitle: 'Classifiers',
				tfUserGroupTitle: 'User groups',
				tfUserTitle: 'Users',
				tfEBookBankTitle: 'eBook Banks',
				tfEPaymentBankTitle: 'eBook Payments',
				tfEBookReportTitle: 'eBook Reports',
				tfEBookReportItemTitle: 'eBook Report items',
				tfEBookSupplierTitle: 'eBook Suppliers',
				tfEBookTerminalTitle: 'eBook Terminals',
			}
		},
		plain: {
			FieldEnum: {
				enumNotDefined: 'Not defined'
			},
			FieldFile: {
				buttonSelectFile: 'Select file',
				buttonDownloadFile: 'Download file to local computer',
				buttonClearFile: 'Clear file',
			},
			FieldImage: {
				buttonSelectImage: 'Select image',
				buttonClearImage: 'Clear image'
			},
			FieldList: {
				labelEmptyTable: 'No values'
			},
			FieldList_Enum: {
				labelEmptyTable: 'No values'
			},
			FieldList_MultilangString: {
				enumNotDefined: 'Not defined',
				labelEmptyTable: 'No values'
			},
			FieldList_Reference: {
				labelEmptyTable: 'No values'
			},
			FieldList_TableGroup: {
				buttonAdd: 'Add new row',
				buttonClone: 'Clone selected row'
			},
			FieldReference: {
				buttonClearTooltip: 'Clear reference',
				buttonSelect: 'Select',
				buttonSelectTooltip: 'Select referenced field',
				messageNotDefined: 'Not defined'
			},
			FieldString: {
				buttonGoToUrl: 'Go',
				buttonGoToUrlTooltip: 'Go to defined URL',
				valueNotDefined: 'Not defined',
			},
			PlainPage: {
				dlgTitle: 'Saving',
				msgActivateForm: 'Activate form...',
				buttonSave: 'Save',
				msgPleaseWait: 'Please, wait',
				msgSaved: 'Saved successfully',
				labelCbSystemData: 'System data',
				labelSystemData: 'System data',
				msgFetching: 'Fetching...',
				labelCard: 'Card', 
				labelClass: 'Class', 
				labelOwnerGroup: 'Owner (group)', 
				labelOwnerUser: 'Owner (user)',
				labelCreateUser: 'Create user', 
				labelCreateTime: 'Create time', 
				labelModifyUser: 'Modify user', 
				labelModifyTime: 'Modify time',
			}
		},
		rateview: {
			button: {
				ButtonUshExport: {
					buttonExport: "Export",
					buttonExportTooltip: "Export marked objects to USH XML",
					msgSelectDataForExport: 'Select data for export'
				},
				ButtonUshImport: {
					buttonImport: "Import",
					buttonImportTooltip: "ІImport objects from USH XML"
				}
			},
			conditions: {
				conditions: {
					none: {
						title: 'No conditions'
					},
					isNotNull: {
						title: 'Defined'
					},
					isNull: {
						title: 'Not defined'
					},
					include: {
						title: 'Includes'
					},
					notInclude: {
						title: 'Does not include'
					},
					startsWith: {
						title: 'Starts with'
					},
					notStartsWith: {
						title: 'Does not start with'
					},
					equals: {
						title: 'Equals'
					},
					notEquals: {
						title: 'Does not equal'
					},
					containsText: {
						title: 'Contains text'
					},
					notContainsText: {
						title: 'Does not contain text'
					},
					containsAllWords: {
						title: 'Contains all words'
					},
					containsAnyWord: {
						title: 'Contains at least one word'
					},
					greater: {
						title: 'Greater than'
					},
					greaterEquals: {
						title: 'Greater or equals'
					},
					less: {
						title: 'Less than'
					},
					lessEquals: {
						title: 'Less or equals'
					},
					between: {
						title: 'Between'
					},
					notBetween: {
						title: 'Not between'
					},
					monthInclude: {
						title: 'Month include'
					},
				},
				ActiveFilterList: {
					buttonClear: 'Clear',
					buttonClearTooltip: 'Clear selected filter',
					labelActiveFilterList: 'Active filters'
				},
				ButtonViewConditions: {
					title: 'Conditions',
					tooltip: 'Edit extra view conditions'
				},
				FilterBoolean: {
					labelYes: 'Yes'
				},
				TabRowColumns: {
					labelRowsOnPage: 'Rows on page',
					labelPresentationColumn: 'Presentation column',
					colTitleColumn: 'Column',
					colTitleVisible: 'Visible',
					colTitleSum: 'Sum',
					colTitleAvg: 'Average',
					colTitleMin: 'Minimum',
					colTitleMax: 'Maximum',
					buttonMoveUp: 'Move up',
					buttonMoveDown: 'Move down'		
				},
				TabSorting: {
					labelColumns: 'Columns',
					labelSorting: 'Sorting',
					buttonMoveUp: 'Move up',
					buttonMoveDown: 'Move down',
					rbAscending: 'Ascending',
					rbDescending: 'Descending'
				},
				ViewConditionsModal: {
					header: 'View conditions',
					tabRowsColumns: 'Rows and columns',
					tabFilters: 'Filters',
					tabSorting: 'Sorting',
					buttonOk: 'Ok',
					buttonCancel: 'Cancel'
				}
			},
			filter: {
				FilterEnumField: {
					noCondition: '<No condition>'
				},
				FilterObjectRef: {
					buttonClearTooltip: 'Clear this filter'
				},
				FilterOwn : {
					title: 'Own only'
				},
				RateViewFilters: {
					title: 'Filters',
					buttonClear: 'Clear',
					buttonClearTooltip: 'Clear all filters'
				}
			},
			modal: {
				UpdateFieldModal: {
					title: 'Change objects',
					msgPleaseWait: 'Please, wait ...',
					labelChange: 'Change',
					optionMarked: 'Marked',
					optionSelected: 'Selected',
					labelRebuild: 'Rebuild:',
					cbObjects: 'Objects',
					cbDownloadImages: 'load images',
					cbCards: 'Cards',
					cbWords: 'Words',
					cbLinks: 'Links',
					cbFiles: 'Files',
					columnField: 'Field',
					columnLang: 'Language',
					columnValue: 'Value',
					columnClear: 'Clear',
					columnOnlyEmpty: 'Only empty',
					labelNewValue: 'New value',
					buttonAdd: 'Add',
					buttonDelete: 'Delete',
					buttonUpdate: 'Update',
					buttonSelectTooltip: 'Select referenced field',
					buttonClearTooltip: 'Clear reference',
					messageNotDefined: 'Not defined'
				},
				UshExportModal: {
					title: 'Export',
					waiting: 'Please wait ...',
					file: 'File',
					buttonExport: 'Export',
					buttonExportTooltip: 'Export marked objects to XML',
					msgInternalError: 'Internal error on server',
					error: 'Error'
				},
				UshImportModal: {
					noFileSelected: 'No file selected',
					title: 'Import',
					optionNetworkImport: 'Network import ( HTTP )',
					optionLocalImport: 'Local import',
					waiting: 'Please wait ...',
					file: 'File',
					buttonImport: 'Import',
					buttonImportTooltip: 'Import objects from XML'
				}
			},
			ButtonChangeOwner: {
				buttonChangeOwner: 'Change owner',
				buttonChangeOwnerTooltip: 'Change owner for marked objects',
				msgNoRowSelected: 'No row selected',
				msgOwnerChanged: 'Owner changed'
			},
			ButtonFieldUpdate: {
				buttonUpdateField: 'Change field',
				buttonUpdateFieldTooltip: 'Change field for marked/selected objects',
				msgUpdated: 'Changed successfully',
			},
			ButtonGlobalIdUpdate: {
				buttonUpdateGlobalId: 'Change global id',
				buttonUpdateGlobalIdTooltip: 'Change field for marked objects',
				msgUpdated: 'Changed successfully',
			},
			ButtonMergeInstances: {
				buttonMergeInstances: 'Merge objects',
				buttonMergeInstancesTooltip: 'Merge objects',
				msgSelectAtLeast2: 'Select at least 2 objects',
				msgMerged: 'Merged successfully'
			},
			RateView: {
				msgSetConditionsAndRefresh: 'Set view conditions and click button \"Refresh\"',
				msgLoading: 'Data loading...', 
				msgError: 'Error happened...', 
				msgInitForm: 'Init form...',
				msgConfirmDeleteMarked: 'Delete marked objects?',
				
				labelViewConditions: 'View conditions',
				labelPageSize: 'Rows',
				labelPageNum: 'Page',
				labelRecordCount: 'Records',

				buttonParameters: 'Parameters',
				buttonParametersTooltip: 'Parameters',
				buttonRefresh: 'Refresh',
				buttonRefreshTooltip: 'Refresh table form\'s content',
				buttonFirstPage: 'First',
				buttonFirstPageTooltip: 'First',
				buttonPrevPage: 'Previous',
				buttonPrevPageTooltip: 'Previous',
				buttonExplicitPage: 'Go',
				buttonExplicitPageTooltip: 'Explicit page',
				buttonNextPage: 'Next',
				buttonNextPageTooltip: 'Next',
				buttonLastPage: 'Last',
				buttonLastPageTooltip: 'Last',
				buttonAddInstance: 'Add',
				buttonAddInstanceTooltip: 'Add',
				buttonEditInstance: 'Edit',
				buttonEditInstanceTooltip: 'Edit',
				buttonDeleteInstances: 'Delete',
				buttonDeleteInstancesTooltip: 'Delete',
				buttonSelect: 'Select',
				buttonSelectTooltip: 'Select',
				buttonTransition: 'Transition',
				buttonTransitionTooltip: 'Go to another form',
				buttonHtml: 'HTML',
				buttonHtmlTooltip: 'HTML',
				buttonPdf: 'PDF',
				buttonPdfTooltip: 'PDF',
				buttonXml: 'XML',
				buttonXmlTooltip: 'XML',
				
				menuHideLeftSide: 'Hide panel "View conditions"',
				menuShowLeftSide: 'Show panel "View conditions"',
				optionModeTable: 'Table',
				optionModeTree: 'Tree',
			}
		},
		tableview: {
			TableView: {
				aggregationSum: 'Sum',
				aggregationAvg: 'Average',
				aggregationMin: 'Minimum',
				aggregationMax: 'Maximum',
			}
		},
		treeview: {
			TreeView: {
				waiting: 'Please wait ...',
			},
			TreeNode: {
				buttonCollapse: 'Collapse',
				buttonCollapseTooltip: 'Collapse group of fields',
				buttonExpand: 'Expand',
				buttonExpandTooltip: 'Expand  group of fields',
				waiting: 'Please wait ...',
			}
		}
	},
	modal: {
		exim: {
			ClassifierImportModal: {
				title: 'Import classifier',
				labelType: 'Type',
				labelClassifier: 'Classifier',
				labelFile: 'File',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import classifier from file',
				msgSuccess: 'Imported successfully',
				waiting: 'Please wait ...',
			},
			OnixExportModal: {
				title: 'Export',
				waiting: 'Please wait ...',
				file: 'File',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked messages to ONIX',
				msgInternalError: 'Internal error on server',
				error: 'Error'
			},
			OnixImportModal: {
				title: 'Import from ONIX',
				optionNetworkImport: 'Network import ( HTTP )',
				optionLocalImport: 'Local import',
				optionElibriImport: 'Elibri import',
				labelFile: 'File',
				labelSaveOriginal: 'Save original',
				labelDownloadImages: 'Download images',
				labelIgnoreInvalid: 'Ignore invalid',
				labelIgnoreWarning: 'Ignore warnings',
				labelTestMode: 'Test mode (do not save objects)',
				buttonSelectFile: 'Select...',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import from Onix',
				buttonRepeat: 'Repeat import',
				buttonRepeatTooltip: 'Repeat import from Onix',
				msgSuccess: 'Imported successfully',
				waiting: 'Please wait ...',
			}
		},
		exportbook: {
			ExportBook: {
				title: 'Export books',
				waiting: 'Please wait ...',
				file: 'File',
				format: 'Format',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked biblio records to selected format',
				msgInternalError: 'Internal error on server',
				error: 'Error'
			}
		},
		importbook: {
			ImportBook: {
				noFileSelected: 'No file selected',
				title: 'Import books',
				waiting: 'Please wait ...',
				file: 'File',
				format: 'Format',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import biblio records from file of selected format'
			}
		},
		importonix: {
			ImportOnix: {
				noFileSelected: 'No file selected',
				title: 'Import ',
				waiting: 'Please wait ...',
				file: 'File',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import messages from Onix',
				msgSuccess: 'Imported successfully'
			}
		},
		message: {
			Message : {
				header: 'Message'
			}
		},
		rateview: {
			ChangeOwnerModal: {
				title: 'Change owner',
				labelGroup: 'Group',
				labelUser: 'User',
				cbOnlySelectedGroup: 'Only selected group',
				buttonChange: 'Change',
				msgPleaseWait: 'Please, wait ...'
			},
			MergeInstancesModal: {
				title: 'Merge objects',
				msgPleaseWait: 'Please, wait ...',
				tdCardMaster: 'Card',
				tdOwnerUser: 'Owner',
				tdOwnerGroup: 'Group',
				tdCreateUser: 'Created',
				tdCreateDate: 'Create time',
				tdModifyUser: 'Modified',
				tdModifyDate: 'Modify time',
				buttonMoveUp: 'Move up',
				buttonMoveDown: 'Move down',
				buttonMerge: 'Merge',
			},
			UpdateGlobalIdModal: {
				title: 'Update global id',
				msgPleaseWait: 'Please, wait ...',
				labelNewValue: 'New value',
				buttonGenerate: 'Delete',
				buttonUpdate: 'Update'
			}
		},
		selectinstance: {
		}
	},
	pages: {
		dbmanager: {
			DbManagerPage: {
				title: 'Database manager',
				labelWait: 'Please, wait...',
				buttonPerform: 'Perform',
				tabCommon: 'Common',
				tabObjects: 'Objects',
				tabCards: 'Cards',
				tabLinks: 'Links',
				tabFiles: 'Files',
				tabWords: 'Words',
				cbResolveReferences: 'Resolve references',
				cbDeleteNotUsedFiles: 'Delete not used files',
				labelPatch: 'Patch',
				labelRebuildObjects: 'Rebuild objects',
				cbDownloadImages: 'Download images',
				labelRebuildCards: 'Rebuild cards',
				labelRebuildLinks: 'Rebuild links',
				labelRebuildFiles: 'Rebuild files',
				labelRebuildWords: 'Rebuild words',
			}
		},
		plain: {
			AuthorityAuthor: {
				title: 'Authority author'
			},
			AuthorityPublisher: {
				title: 'Authority publisher'
			},
			Book: {
				title: 'Book'
			},
			Classifier: {
				title: 'Classifier'
			},
			Confirmation: {
				title: 'Confirmation'
			},
			Contact: {
				title: 'Contact'
			},
			Derivative: {
				title: 'Derivative'
			},
			DigitalStore: {
				title: 'Digital store'
			},
			Estimation: {
				title: 'Estimation'
			},
			Event: {
				title: 'Event'
			},
			Honor: {
				title: 'Honor'
			},
			Library: {
				title: 'Library'
			},
			Location: {
				title: 'Place'
			},
			Message: {
				title: 'Message',
				titleProducts: 'Products',
				buttonSelect: 'Select',
				buttonSelectTooltip: 'Select product and include it to message'
			},
			Offer: {
				title: 'Offer'
			},
			Order: {
				title: 'Order'
			},
			Organization: {
				title: 'Organization'
			},
			PlainEBookReportPage: {
				msgSelectSupplier: 'Select supplier first',
				buttonAdd: 'Add report row',
				buttonClone: 'Add report row clone'
			},
			Procurement: {
				title: 'Procurement'
			},
			Product: {
				title: 'Product'
			},
			Provider: {
				title: 'Provider'
			},
			Topic: {
				title: 'Topic'
			},
			UserGroup: {
				title: 'User group'
			},
			User: {
				title: 'User'
			}
		},
		profile: {
			ProfilePage: {
				buttonChange: 'Change', 
				buttonRefresh: 'Refresh',

				colHeaderClass: 'Class',
				colHeaderCreate: 'create',
				colHeaderDelete: 'delete',
				colHeaderRead: 'read',
				colHeaderUpdate: 'update',

				labelAccessLevel: 'Access level',
				labelAccess: 'Access',
				labelActions: 'Actions',
				labelChangePassword: 'Change password',
				labelForms: 'Forms',
				labelGroups: 'Groups',
				labelLogin: 'Login',
				labelName: 'Name',
				labelUser: 'User',
				labelOrganization: 'Organization',
				labelNewPassword: 'New password',
				labelObjects: 'Objects',
				labelOldPassword: 'Old password',
				labelLanguage: 'Language', 
				labelButtonView: 'Button view',
				
				msgPasswordChanged: 'Password changed',
				
				permissionAll: 'all',
				permissionGroup: 'group',
				permissionOwn: 'own',
				permissionCreate: 'create', 
				permissionReadAll: 'read all',
				permissionReadGroup: 'read group',
				permissionReadOwn: 'read own',
				permissionUpdateAll: 'update all',
				permissionUpdateGroup: 'update group',
				permissionUpdateOwn: 'update own',
				permissionDeleteAll: 'delete all',
				permissionDeleteGroup: 'delete group',
				permissionDeleteOwn: 'delete own',
			}
		},
		tableform: {
			AuthorityAuthorList: {
				formTitle: 'Author authorities',
				modalExportTitle: 'Export',
				waiting: 'Please wait ...',
				modalExportLabelFile: 'File',
				modalExportLabelFormat: 'Format',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked authors to selected format',
				buttonTryAgain: 'Try again',
				msgInternalError: 'Internal error on server',
				error: 'Error'
			},
			AuthorityPublisherList: {
				formTitle: 'Publisher authorities',
				modalExportTitle: 'Export',
				waiting: 'Please wait ...',
				modalExportLabelFile: 'File',
				modalExportLabelFormat: 'Format',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked brands to selected format',
				buttonTryAgain: 'Try again',
				msgInternalError: 'Internal error on server',
				error: 'Error'
			},
			BookList: {
				formTitle: 'Books',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import objects from XML',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked biblio records to selected format',
				modalExportTitle: 'Export books',
				modalImportTitle: 'Import books',
				labelFile: 'File',
				labelFormat: 'Format',
				textSelectFile: 'You have to select file',
				buttonTryAgain: 'Try again',
				waiting: 'Please wait ...',
				messageImportComplete: 'Successfully imported',
			},
			ClassifierList: {
				formTitle: '',
				buttonImport: 'Import',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			ConfirmationList: {
				formTitle: 'Confirmations',
			},
			ContactList: {
				formTitle: 'Contacts',
			},
			DerivativeList: {
				formTitle: 'Derivatives',
			},
			DigitalStoreList: {
				formTitle: 'Digital stores',
			},
			EBookBankList: {
				formTitle: 'eBook Banks',
			},
			EBookPaymentList: {
				formTitle: 'eBook Payments',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import reports from XML',
				buttonDownload: 'Download',
				buttonDownloadTooltip: 'Download payments',
				modalImportTitle: 'Import reports',
				modalDownloadTitle: 'Download reports',
				labelFile: 'File',
				labelDateFrom: 'Date from',
				labelDateTo: 'Date to',
				textSelectFile: 'You have to select file',
				buttonTryAgain: 'Try again',
				waiting: 'Please wait ...',
				messageImportComplete: 'Successfully imported',
			},
			EBookReportList: {
				formTitle: 'eBook Reports',
				buttonImport: 'Import',
				buttonImportTooltip: 'Import reports from XML',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export reports to XML',
				modalExportTitle: 'Export reports',
				modalImportTitle: 'Import reports',
				optionNetworkImport: 'Network import ( HTTP )',
				optionLocalImport: 'Local import',
				labelFile: 'File',
				textSelectFile: 'You have to select file',
				buttonTryAgain: 'Try again',
				waiting: 'Please wait ...',
				messageImportComplete: 'Successfully imported',
			},
			EBookReportItemList: { 
				formTitle: 'eBook Report items',
			},
			EBookSupplierList: {
				formTitle: 'eBook Suppliers',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export marked records to selected format',
				buttonUploadWhiteList: 'Upload white list',
				modalExportTitle: 'Export suppliers',
				labelFile: 'File',
				labelFormat: 'Format',
				textSelectFile: 'You have to select file',
				buttonTryAgain: 'Try again',
				waiting: 'Please wait ...',
			},
			EBookTerminalList: { 
				formTitle: 'eBook Terminals',
			},
			EstimationList: {
				formTitle: 'Estimations',
			},
			EventList: {
				formTitle: 'Events',
			},
			HonorList: {
				formTitle: 'Honors',
			},
			LibraryList: {
				formTitle: 'Libraries',
			},
			LocationList: {
				formTitle: 'Locations',
			},
			MessageList: {
				formTitle: 'Messages',
				buttonExport: 'Export',
				buttonExportTooltip: "Експортувати позначені обʼєкти в форматі xml",
				buttonImport: 'Import',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			OfferList: {
				formTitle: 'Offers',
				createOrder: 'Create order',
				createProcurements: 'Create procurements'
			},
			OrderList: {
				formTitle: 'Selected',
			},
			OrganizationList: {
				formTitle: 'Organizations',
			},
			ProcurementList: {
				formTitle: 'Procurements',
			},
			ProductList: {
				formTitle: 'Products',
				optionMarked: 'Marked',
				optionSelected: 'Selected',
				modalLabelFile: 'File',
				labelFormat: 'Format',
				buttonExport: 'Export',
				buttonExportTooltip: 'Export products in selected format',
				buttonTryAgain: 'Retry',
				modalExportTitle: 'Export products',
				waiting: 'Please wait ...',
			},
			ProviderList: {
				formTitle: 'Providers',
				buttonImport: 'Import',
				buttonImportTooltip: 'Імпортувати обʼєкти з xml файлу'
			},
			TopicList: {
				formTitle: 'Classifiers',
			},
			UserGroupList: {
				formTitle: 'User groups',
			},
			UserList: {
				formTitle: 'Users',
			}
		}
	},
	util: {
		restapi: {
			msgNetworkError: 'Network error'
		}
	}
}

export default ResourceEng;
