import React from 'react';
import stylesPlain from '../../../components/plain/plain.module.css';

import FieldList from '../../../components/plain/FieldList';
import FieldList_Reference from '../../../components/plain/FieldList_Reference';
import SelectInstance from '../../../modal/selectinstance/SelectInstance';

import { getDefinedValue, getDefinedItem } from '../../../util/tools';
import { TOPIC_PATH } from '../../../util/routes';
import { FORM_TOPIC_TABLE } from '../../../util/consts/forms';
import { performApiRequest, MASTER_LOAD_REF_LIST_API_URL, QUERY_GETPAGE_API_URL } from '../../../util/restapi';

import iconAdd from '../../../assets/icons/png24x24/Add.png';

class TabBrTopicLinks extends React.Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		let plain = this.props.plain;
		let readOnly = this.props.mode == 'special';
		let instance = this.props.instance;
		let parentField = this.props.parentField;
		if( ! parentField ) {
			parentField = instance.rootField;
		}
		return(
			<div style={{padding: '20px' }}>
				<TopicList instance={instance} path={'topicLink'} parentField={parentField} 
						labelEmptyToHeader={true} ignoreTitle={true}
						plain={plain} plainDirtyCounter={plain.state.plainDirtyCounter}
						readOnly={readOnly} 
				/>
			</div>
		);
	}
}

class TopicList extends FieldList {

	selectInstanceRvContext = {
		form: FORM_TOPIC_TABLE,
		formTitle: 'Теми'
	}
	
	constructor(props) {
		super(props);
		this.state.serviceValue = '';
		this.state.isSelectInstanceModalOpen = false;
	}

	handleServiceValueChange = e => {
		this.setState({ serviceValue: e.target.value });
	};

	addTopics = async () => {
		var serviceValue = this.state.serviceValue; 
		if( serviceValue ) {
			var query = {
				queryId: 'topic_table',
				listColumn: [ {id: 'card'}, {id: 'topic_add'}, {id: 'topic_mod'}],
				listPredicate: [ { predicateId: 'code', conditionId: 'startsWith', value: serviceValue } ],
				listSorting: [	{ sortingId: 'cl_name', ascending: true } ],
				page: 1, pageSize: 15 
			};
			var filtersInitData = [
				{predicateId: 'code', value: serviceValue}
			];
			var response = await performApiRequest( QUERY_GETPAGE_API_URL, query );
			if( response.data.data.length == 1 ) {
				var rows = [ response.data.data[0].id ];
				this.onInstanceSelected( rows );
			} else {
				this.selectInstanceRvContext.filtersInitData = filtersInitData;
				this.setState({ isSelectInstanceModalOpen: true });
				var rateView = this.selectInstanceRvContext.rateView;
				rateView.updateData( response.data ); 
			}
			
		} else {
			this.setState({ isSelectInstanceModalOpen: true });
		}
	}
	onInstanceSelected = async (ids) => {
		if( ids.length == 0 )
			return;
		var request = {
			listObjectId: ids
		}
		var response = await performApiRequest(MASTER_LOAD_REF_LIST_API_URL, request);
		if( ! response.success ) {
			this.props.plain.app.showResponse( response );
			return;
		}
		this.closeSelectInstance();
		for( let ref of response.data.listObjectRef ) {
			var unique = true;
			for( let item of this.field.value ) {
				if( item.value.topicLinkValue.value && item.value.topicLinkValue.value.id == ref.id ) {
					unique = false;
					break;
				}
			}
			if( unique ) {
				var newItem = this.props.instance.addListItem( this.field, null );
				this.props.instance.setFieldValue(newItem.value.topicLinkValue, ref);
			}
		}
	}
	closeSelectInstance = () => {
		this.setState({ isSelectInstanceModalOpen: false });
	}

	createButtonAddHtml() {
		let readOnly = getDefinedValue( this.props.readOnly, false);
		let buttonAddHtml = (
			<div style={{display: 'flex', alignItems: 'center'}}>
				<input type='text' size='20' style={{height: '26px'}} 
					value={this.state.serviceValue} onChange={ this.handleServiceValueChange} 
				/>
				<button type='button' className={stylesPlain.buttonIcon} style={{marginLeft: '5px', marginRight: '5px'}}
					onClick={this.addTopics} disabled={readOnly}
				>
					<img src={iconAdd} />
				</button>
			</div>
		);
		return buttonAddHtml;
	}
	
	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		
		let headerHtml = this.createHeaderHtml();
		let cardsHtml = this.field.value.map((itemField, index) => {
			let divStyle = {
				marginTop: '3px',
				padding: '7px',
				cursor: 'pointer',
				backgroundColor: 'lightGray',
				minHeight: '18px'
			}
			if( index == this.state.selectedIndex )
				divStyle.backgroundColor = 'lightBlue';
			let card = '';
			if( itemField.value.topicLinkValue.value )
				card = itemField.value.topicLinkValue.value.card;
			if( ! card )
				card = '...' 
			return (
				<div key={index} style={divStyle} onClick={ () => {this.handleRowClick(index)} }>
					{card}
				</div>
			)
		});
		return (
			<div style={{display: 'flex'}}>
				<div style={{marginRight: '15px'}}>
					{headerHtml}
					{cardsHtml}
				</div>
				<TopicPanel topicListPanel={this} topicIndex={this.state.selectedIndex} readOnly={this.props.readOnly} />
				<SelectInstance 
					isModalOpen={this.state.isSelectInstanceModalOpen} 
					closeModal={this.closeSelectInstance}
					title={'Теми'}
					onInstanceSelected={this.onInstanceSelected}
					rvContext = {this.selectInstanceRvContext}
				/>
			</div>
		)
	}
}

class TopicPanel extends React.Component {
	
	refTopicNodes = [
		{
			classId: 'Topic', plainFormPath: TOPIC_PATH, 
			selectInstanceFormId: FORM_TOPIC_TABLE,
			selectInstanceFormTitle: 'Теми'
		}
	];
	
	/*
		topicListPanel
		topicIndex
		readOnly
	*/
	constructor(props) {
		super(props);
	}

	render() {
		let topicListPanel = this.props.topicListPanel;
		let index = this.props.topicIndex;
		
		if( index < 0 )
			return null;
		let fieldGroup = topicListPanel.field.value[index];
		let plain = topicListPanel.plain;
		let instance = topicListPanel.props.instance;

		let card = getDefinedItem(fieldGroup, ['value', 'topicLinkValue', 'value', 'card'], '');

		return (
			<div style={{display: 'flex', flexGrow: '1', backgroundColor: 'lightGray'}}>
				<div style={{margin: '10px', padding: '10px', flexGrow: '1', border: '1px solid gray'}}>
					<div style={{display: 'flex', alignItems: 'center', marginBottom: '15px'}}>
						{card}
					</div>
					<FieldList_Reference path={'topicLinkSpecValue'} instance={instance} parentField={fieldGroup} plain={plain} 
						styles={{divMain: {marginTop: '10px'}}}
						labelEmptyToHeader={true}
						refNodes={this.refTopicNodes}
						readOnly={this.props.readOnly}
					/>
				</div>
			</div>
		)
	}
}

export default TabBrTopicLinks;