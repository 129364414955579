import React from 'react';

import FieldList from './FieldList';

import { getDefinedValue } from '../../util/tools';

export const DIRECTION_HORIZONTAL = 'horizontal';
export const DIRECTION_VERTICAL = 'vertical';

class FieldList_CardsPanel extends FieldList {
	
	/*
		direction                  (horizontal (default) | vertical)
		itemComponent
		buildItemComponent
		itemCardBuilder
		itemCardHtmlBuilder
		isCardBlockDirty
		styles
			divMain
			divCardsBlock
			divCardListStyle
			divCard
	*/
	constructor(props) {
		super(props);
		this.init();
		this.state.compoundCard = '';
		this.state.dirtyCounter = 0;
	}
	
	getCompoundCard = () => {
		let compoundCard = '';
		for(let i=0; i < this.field.value.length; i++) {
			let itemField = this.field.value[i];
			compoundCard += this.props.itemCardBuilder(itemField, i);
		}
		return compoundCard;
	}
	async handleInstanceChange(event) {
		await super.handleInstanceChange( event );
		
		if( this.props.isCardBlockDirty ) {
			if( this.props.isCardBlockDirty(event) ) {
				this.setState({dirtyCounter: this.state.dirtyCounter + 1});
				return;
			}
		}
		
		let compoundCard = this.getCompoundCard();
		if( compoundCard != this.state.compoundCard ) {
			this.setState({compoundCard: compoundCard});
		}
	}
	
	createLabelStyle() {
		let labelStyle = super.createLabelStyle();
		labelStyle.fontWeight = 'bold';
		labelStyle.fontSize = '14pt';
		return labelStyle;
	}
	render() {
		if( ! this.field ) {
			return this.createDivUnknownField();			
		}
		let styles = getDefinedValue( this.props.styles, {});
		let divCardsBlockStyle = Object.assign( {},
			localStyles.divCardsBlock, 
			getDefinedValue(styles.divCardsBlock, {}));
		let divCardStyle = Object.assign( {},
			localStyles.divCard, 
			getDefinedValue(styles.divCard, {}));

		let cardsHtml = this.field.value.map((itemField, index) => {
			let divStyle = {...divCardStyle};
			if( index == this.state.selectedIndex )
				divStyle.backgroundColor = 'lightBlue';
			let card = null;
			if( this.props.itemCardHtmlBuilder ) {
				card = this.props.itemCardHtmlBuilder(itemField, index);
			} else {
				card = this.props.itemCardBuilder(itemField, index);
				if( ! card )
					card = '...'
			} 
			return (
				<div key={index} style={divStyle} onClick={ () => {this.handleRowClick(index)} }>
					{card}
				</div>
			)
		});
		let listItemInfo = {
			index: this.state.selectedIndex,
			isLastItem: false,
			divButtonsHtml: null
		};
		
		let selectedItemHtml = null; 
		if( 0 <= this.state.selectedIndex && this.state.selectedIndex < this.field.value.length ) {
			let activeField = this.field.value[this.state.selectedIndex];
			if( this.props.buildItemComponent ) {
				let listItemInfo = null;
				selectedItemHtml = this.props.buildItemComponent(activeField, listItemInfo); 
			} else {
				selectedItemHtml = (
					<this.props.itemComponent fieldGroup={activeField} listItemInfo={listItemInfo} 
						instance={this.props.instance} plain={this.props.plain} readOnly={this.props.readOnly}
					/>
				);
			}
		}

		let divCardListStyle = Object.assign( {}, getDefinedValue(styles.divCardList, {}));
		let divMainStyle = Object.assign( {},
			getDefinedValue(styles.divMainStyle, {}), // Obsolete
			getDefinedValue(styles.divMain, {}));
		let direction = getDefinedValue(this.props.direction, DIRECTION_HORIZONTAL);
		if( direction == DIRECTION_HORIZONTAL )
			divMainStyle.display = 'flex';		

		
		let headerHtml = this.createHeaderHtml();
		
		return (
			<div style={divMainStyle}>
				<div style={divCardsBlockStyle}>
					{headerHtml}
					<div style={divCardListStyle}>
						{cardsHtml}
					</div>
				</div>
				{selectedItemHtml}
			</div>
		)
	}
}

const localStyles = {
	divCardsBlock: {
		padding: '10px',
		backgroundColor: 'lightGray',
	},
	divCardsHeader: {
		marginBottom: '10px',
		display: 'flex',
		alignItems: 'center',
		fontWeight: 'bold',
		fontSize: '13pt'
	},
	divCard: {
		padding: '7px',
		cursor: 'pointer',
		backgroundColor: 'lightGray',
		minHeight: '18px'
	}
}

export default FieldList_CardsPanel;
