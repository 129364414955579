import React, { createRef } from 'react';
import Modal from 'react-responsive-modal';
import styles from './modal.module.css';

import { getCurrentResource } from '../../../util/tools';
import { 
	ACTION_USH_IMPORT_API_URL, ACTION_USH_IMPORT_LOCAL_API_URL, 
	performImportRequest, performApiRequest 
} from '../../../util/restapi';

const FORM_STATE_GET_DATA = 'gd';
const FORM_STATE_WAITING = 'w';
const FORM_STATE_IMPORT_COMPLETE = 'ic';

const IMPORT_MODE_NETWORK = 'network';
const IMPORT_MODE_LOCAL = 'local';

class UshImportModal extends React.Component {

	constructor(props) {
		super(props);
		props.holder.componentUshImportModal = this;
		this.resource = getCurrentResource().components.rateview.modal.UshImportModal;
		this.importOptions = {};
		this.importOptions = {
			file: null
		}
		this.fileImportRef = createRef();
		this.state = {
			isOpen: false,
			importMode: IMPORT_MODE_NETWORK,
			fileName: this.resource.noFileSelected,
			filePath: '',
			formState: FORM_STATE_GET_DATA
		}
		this.init();		
	}
	
	onImportModeChange = (event) =>	{
		this.setState({ importMode: event.target.value });
	}
	onFilePathChange = (event) =>	{
		this.setState({ filePath: event.target.value });
	}
	
	init = () => {
		this.fileName = "Not selected";
		this.importResultMessage = '';
		this.importOptions.file = null;
		this.setState({
			formState: FORM_STATE_GET_DATA, 
			fileName: this.resource.noFileSelected,
			importMode: IMPORT_MODE_NETWORK,
			filePath: '',
		});
	}

	styleModal = {
		modal: {
			background: '#fff',
			padding: '0px',
			borderRadius: '21px',
			maxWidth: '500px',
			width: '100%',
		},
		closeButton: {
			top: '10px',
			right: '10px',
			fill: '#F00',
			outline: 'none',
		},
	};

	handleFileSelect = (e) => {
		let files = e.target.files;
		if( files && files.length > 0 ) {
			this.importOptions.file = files[0];
			this.setState({fileName: this.importOptions.file.name})
		}
	}	

	startImport = async () => {
		this.setState({formState: FORM_STATE_WAITING});
		let importOptions = {
			format: 'xml',
			rewrite: true,
			rewriteIfNewer: true,
		}
		
		let response;
		if( this.state.importMode == IMPORT_MODE_NETWORK ) {
			const form = new FormData();
			form.append('importOptions', JSON.stringify(importOptions));
			form.append('data', this.importOptions.file);
			response = await performImportRequest(ACTION_USH_IMPORT_API_URL, form);
		} else if( this.state.importMode == IMPORT_MODE_LOCAL ) {
			importOptions.filePath = this.state.filePath;
			response = await performApiRequest( ACTION_USH_IMPORT_LOCAL_API_URL, importOptions );
		} else {
			response = {success: false, message: 'Unknown import mode'};
		}

		if( response.success && ! response.message )
			this.importResultMessage = "Success";
		else
			this.importResultMessage = response.message;
		this.setState({formState: FORM_STATE_IMPORT_COMPLETE});
	}

	render() {
		return (
			<Modal 
				open={this.props.isModalOpen} onClose={this.props.close} center styles={this.styleModal}
				closeOnOverlayClick={false} 
			>
				<ImportForm holder={this} />
			</Modal>
		);
	}
}

function ImportForm(props) {
	let res = props.holder.resource;
	let holder = props.holder
	let inputDisabled = props.holder.state.formState != FORM_STATE_GET_DATA;

	let divWaiting = (null);
	let divMessage = (null);
	if( props.holder.state.formState == FORM_STATE_GET_DATA ) {
		divWaiting = (null);
		divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_WAITING ) {
		divWaiting = <div className={styles.divWaiting}>{res.waiting}</div>
		divMessage = (null);
	} else if( props.holder.state.formState == FORM_STATE_IMPORT_COMPLETE ) {
		divWaiting = (null)
		divMessage = (
			<textarea className={styles.areaMessage} rows="4" readOnly={true}>
				{props.holder.importResultMessage}
			</textarea>		
		);
	}

	let divFileHtml = null 
	if( holder.state.importMode == IMPORT_MODE_NETWORK ) {
		divFileHtml = (
			<div className={styles.divParameters}>
				<div>
					{res.file}: <span>{props.holder.state.fileName}</span> <button  disabled={inputDisabled} onClick={e => props.holder.fileImportRef.current.click()}>...</button>
				</div>
				<input ref={props.holder.fileImportRef} type={'file'}  className={styles.hiddenFile} onChange={e => props.holder.handleFileSelect(e)} />
			</div>
		);
	} else if( holder.state.importMode == IMPORT_MODE_LOCAL ) {
		divFileHtml = (
			<div className={styles.divParameters}>
				{res.file}: <input type='text' size='40' value={holder.state.filePath} onChange={holder.onFilePathChange} /> 
			</div>
		);
	}

	return (
		<React.Fragment>
			<div className={styles.divHeader}>
				{res.title}
			</div>
			<div className={styles.divParameters}>
				<select value={holder.state.importMode} onChange={holder.onImportModeChange}>
					<option value={IMPORT_MODE_NETWORK}>{res.optionNetworkImport}</option>
					<option value={IMPORT_MODE_LOCAL}>{res.optionLocalImport}</option>
				</select>
			</div>
			{divFileHtml}
			{divWaiting}
			{divMessage}
			<div className={styles.divButtons}>
				<button onClick={props.holder.startImport} disabled={inputDisabled} title={res.buttonImportTooltip}>{res.buttonImport}</button>
			</div>
		</React.Fragment>
	);
}

export default UshImportModal;
